<template>
  <el-card shadow="hover" header class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">分享设置</div>
      <el-button type="primary" @click="saveShareSetting('shareForm')" size="small">保存</el-button>
    </div>
    <el-form :model="shareForm" ref="shareForm" label-width="150px">
      <el-form-item label="分享标题">
        <el-input
          placeholder="请设置分享标题"
          v-model="shareForm.share_name"
          :maxlength="30"
          style="width:300px;"
        ></el-input>
      </el-form-item>
      <el-form-item label="分享描述">
        <el-input
          type="textarea"
          placeholder="请设置分享描述"
          v-model="shareForm.txt_details"
          :rows="3"
          :maxlength="50"
          style="width:300px;"
        ></el-input>
      </el-form-item>
      <el-form-item label="分享图片" prop="validity_year">
        <div style="display:flex">
          <div class="share-img">
            <i v-if="shareForm.video_img" class="el-icon-close del-img" @click="shareForm.video_img = ''"></i>
            <el-upload
              :action="`${MixinUploadApi}`"
              :show-file-list="false"
              :on-success="uploadedImgUrl"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="shareForm.video_img"
                :src="shareForm.video_img"
                style="width:100px;height:100px"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="el-upload__tip" style="line-height: 15px; text-align:left; margin-left: 20px">
            <p>仅支持JPG、PNG格式的图片，大小不超过100kb。</p>
            <p>建议尺寸：100px * 100px</p>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
import XAddressSelect from "../../components/x-address-select/x-address-select";

export default {
  name: "person",
  components: { XAddressSelect },
  data() {
    return {
      shareForm: {
        share_name: "",
        txt_details: "",
        video_img: ""
      }
    };
  },
  mounted() {},
  created() {
    this.getShareInfo();
  },
  computed: {},
  methods: {
    uploadedImgUrl(response) {
      this.shareForm.video_img = response.url;
    },
    beforeAvatarUpload(file) {
      const isformat = ["image/jpeg", "image/png"].indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 < 100;
      if (!isformat) {
        this.$message.error("分享图片仅支持 jpg、png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("分享图片大小不能超过 100kb!");
      }
      return isformat && isLt2M
    },
    saveShareSetting(formName) {
      API_account.share(this.shareForm).then(res => {
        this.$message.success("操作成功");
      });
    },
    getShareInfo() {
      const shop_id = JSON.parse(
        sessionStorage.getItem("from_enter_seller_shop")
      ).shop_id;
      API_account.getShare({ shop_id }).then(res => {
        this.shareForm = {
          share_name: res.share_name && res.share_name.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&'),
          txt_details: res.txt_details && res.txt_details.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&'),
          video_img: res.video_img
        };
        console.log(res);
        this.$forceUpdate();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-upload{
  width: 100%
}
.share-img {
  position: relative;
  width:100px;
  height:100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  .del-img {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
  }
}

/* /deep/ .el-input__inner {
  width: 250px !important;
}
/deep/.el-form-item__content .el-input-group {
  .el-input__inner {
    width: 110px !important;
  }
  .el-input-group__prepend {
    width: 140px !important;
  }
}
/deep/.el-form-item__content .el-input-group {
  width: 250px !important;
} */
.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
